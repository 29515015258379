import React from 'react';
import Header from '../HeaderEn';
import Footer from '../FooterEn'

const Contact = () => {
    return (
        <div>
            <Header/>
            <iframe src="https://forms.gle/aKGJZSzMkhyQLVf98" width="640" height="943" frameborder="0" marginheight="0" marginwidth="0" title='Forma de contacto'>Cargando…</iframe>
            <Footer/>
        </div>
    )
}

export default Contact;