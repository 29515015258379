import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import HomeEn from './components/HomeEn';
import Equipo from './components/Equipo/Equipo';
import EquipoEn from './components/Equipo/EquipoEn';
import Ciudad from './components/Ciudad/Ciudad';
import CiudadEn from './components/Ciudad/CiudadEn';
import Programa from './components/Programa/Programa';
import Contact from '../src/components/Contacto/Contact';
import ContactEn from '../src/components/Contacto/ContactEn';
import Noticias from '../src/components/Noticias/Noticias';
import NoticiasEn from '../src/components/Noticias/NoticiasEn';
import Patrocinadores from '../src/components/Patrocinadores/Patrocinadores';
import PatrocinadoresEn from '../src/components/Patrocinadores/PatrocinadoresEn';

import Proximamente from './components/Proximamente';
import JuradoComponent from './components/JuradoList';
import JuradoComponentEn from './components/JuradoListEn';
import MaestrosComponentEn from './components/MaestrosListEn';
import MstDetailedContainer from './components/MstDetailed';
import MstDetailedEn from './components/MstDetailedEn';
import CnfDetailed from './components/CnfDetailed';
import CnfDetailedEn from './components/CnfDetailedEn';
import PstDetailed from './components/PstDetailed';
import PstDetailedEn from './components/PstDetailedEn';
import JrdDetailedContainer from './components/JrdDetailed';
import JrdDetailedEn from './components/JrdDetailedEn';
import SedesContainer from './components/Sedes';
import SedesEn from './components/SedesEn';
import Cartel from './components/Gallery';
import CartelEn from './components/GalleryEn';
import Soon from './components/ProximamenteEn';
import Workshop from './components/Workshop';
import WorkshopEn from './components/WorkshopEn';



const Routes = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/equipo' component={Equipo} />
            <Route path='/ciudad' component={Ciudad} />
            <Route path='/programa' component={Programa}/>
            <Route path='/noticias' component={Noticias} />
            <Route path='/patrocinadores' component={Patrocinadores} />
            <Route path='/contacto' component={Contact} />

            <Route path='/jurado' component={JuradoComponent} />
            <Route exact path='/mst-details/:guestID' component={MstDetailedContainer} />
            <Route exact path='/cnf-details/:guestID' component={CnfDetailed} />
            <Route exact path='/pst-details/:guestID' component={PstDetailed} />
            <Route exact path='/jrd-details/:guestID' component={JrdDetailedContainer} />
            <Route path='/sedes' component={SedesContainer} />
            <Route path ='/galeria' component={Cartel} />
            <Route path='/talleres' component={Workshop} />
            
            <Route path='/proximamente' component={Proximamente} />

            {/*English*/}
            <Route path='/en' component={HomeEn} />
            <Route path='/team' component={EquipoEn}/>
            <Route path='/city' component={CiudadEn}/>
            <Route path='/news' component={NoticiasEn} />
            <Route path='/sponsors' component={PatrocinadoresEn}/>

            <Route path='/teachers' component={MaestrosComponentEn} />
            <Route path='/jury' component={JuradoComponentEn} />
            <Route exact path='/tch-details/:guestID' component={MstDetailedEn} />
            <Route exact path='/spk-details/:guestID' component={CnfDetailedEn} />
            <Route exact path='/pia-details/:guestID' component={PstDetailedEn} />
            <Route exact path='/jry-details/:guestID' component={JrdDetailedEn} />
            <Route path='/event-locations' component={SedesEn} />
            <Route path='/gallery' component={CartelEn} />
            <Route path='/workshops' component={WorkshopEn} />
            <Route path='/contact' component={ContactEn} />
            <Route path='/soon' component={Soon} />
        </Switch>
    );
}
export default Routes;