import React from "react";
import "../containers/App.css";
//import FavSection from "./FavSection";
import Footer from "./FooterEn";
import Header from "./HeaderEn";
import InvCarousel from "./InvCarousel";
import heroImage from "../img/hero/hero_zapopan.jpg";
import Hero from "./HeroComponent";
import { Col, Container, Row } from "react-bootstrap";

const Home = () => {
  return (
    <div className="App">
      <Header />
      <div>
        <Hero
          image={heroImage}
          title={"Welcome to the Opera Festival"}
          subtitle={"In the city of Zapopan"}
          buttonsHiden={true}
        />
        <div style={{ textAlign: "center" }}>
          <h3 style={{ padding: "35px 100px 0px" }}>About the Festival</h3>
          <div style={{ textAlign: "justify" }}>
            <Container>
              <Row>
                <Col>
                  <p>
                  The Linus Lerner International Singing Festival and Competition 
                  are held with the aim of stimulating the artistic and personal 
                  development of young singers from all over the world and promoting 
                  knowledge and interest in lyrical singing in our country, with 
                  the collaboration of internationally renowned and high-level 
                  teachers. Likewise, we seek to bring opera closer to the Mexican 
                  public with high-quality operatic performances in various formats 
                  such as recitals, gala concerts, singing competition eliminatories, 
                  and full opera productions with live orchestra.
                  </p>
                  <p>
                    The Festival starts with 3 editions of the Oaxaca Opera 
                    Festival, followed by 5 editions of the San Luis Opera 
                    Festival and 1 edition of the Unlimited Opera Festival.
                  </p>

                  <p>
                    Thirteen complete operas with orchestra and high-level 
                    professional productions have been presented, with 25 
                    national and international teachers, more than 1,200 
                    active students from 23 different countries, and more 
                    than 10,000 audience attendees.
                  </p>
                </Col>
                <Col>
                  <img
                    src={require("../img/homeImages/festival_1.jpg")}
                    alt="festival1"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div>
          <h3>About the Competition</h3>
          <div style={{ textAlign: "justify", padding: "35px 100px" }}>
            <Container>
              <Row>
                <Col>
                  <img
                    src={require("../img/homeImages/festival_4.jpg")}
                    alt="festival4"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Col>
                <Col>
                  <p>
                    Thanks to the Competition, which takes place within the Festival, 
                    the winners receive cash incentives, singing courses in the 
                    United States, opera performances outside the country, as well 
                    as international recognition for having won a prize in an 
                    International Competition. More than 88 cash prizes (more 
                    than $88,000.00 dollars) and 2 scholarships abroad have been 
                    awarded, as well as roles in national and international 
                    presentations, concerts and workshops with all expenses paid.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div>{/* <FavSection/> */}</div>
        {/*
        <div style={{backgroundColor: '#313134'}}>
          <MaestrosSection/>
        </div> */}
      </div>
      <InvCarousel />
      <Footer />
    </div>
  );
};

export default Home;
