import React from 'react';
import Header from './HeaderEn';
import './Gallery.css';

const WorkshopEn = () => {
    return (
        <div>
            <Header/>
            <div className='container my-3 p-3 border'>
                <h1>2020</h1>
                <div className='card-deck'>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-andrew-stuckey.jpg')} alt='Master Class Andrew Stuckey' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Andrew Stuckey</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-carsten-wittmoser.jpg')} alt='Master Class Carsten Wittmoser' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Carsten Wittmoser</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-charles-oppenheim.jpg')} alt='Master Class Charles Oppenheim' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Charles Oppenheim</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-conchi-moyano.jpg')} alt='Master Class Conchi Moyano' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Conchi Moyano</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-irena-sylia.jpg')} alt='Master Class Irena Sylia' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Irena Sylia</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-james-demster.jpg')} alt='Master Class James Demster' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-liliana-del-conde.jpg')} alt='Master Class Liliana del Conde' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Liliana del Conde</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-linus-lerner.jpeg')} alt='Master Class Linus Lerner' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Linus Lerner</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-marcelo-buscaino.jpg')} alt='Master Class Marcelo Buscaino' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-maria-russo.jpg')} alt='Master Class Maria Russo' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-sandra-lopez.jpg')} alt='Master Class Sandra Lopez' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class James Demster</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-sergio-vazques.jpg')} alt='Master Class Sergio Vazques' />
                        <div className='card-body'>
                            <h5>5FOSL Master Class Sergio Vazques</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-opera-casos.jpeg')}  alt='Opera, Casos de la Vida Real' />
                        <div className='card-body'>
                            <h5>5FOSL Opera, Casos de la Vida Real</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkshopEn;