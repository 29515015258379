import React from "react";
import Header from "../HeaderEn";
import Hero from "../HeroComponent";
import heroImage from "../../img/hero/CiudadHeroImage.jpg";
import "../../containers/App.css";
import Footer from "../FooterEn";

const Ciudad = () => {
  return (
    <div className="App">
      <Header />
      <div>
        <Hero
          image={heroImage}
          title={"Get to know our city"}
          subtitle={"The beautiful city of Zapopan"}
          buttonsHiden={true}
        />
        {/* Acerca de la ciudad */}
        <div style={{ padding: "35px 100px 0px" }}>
          <h3>About Zapopan</h3>
          <p>
            Zapopan, "City of Children", is one of the 125 municipalities that 
            make up the State of Jalisco. It is located in the central region 
            of the state, in the macro-region of Bajío Occidente or Centro Occidente 
            of Mexico. It is part of the Bajío-Occidente Alliance.
          </p>
          <p>
            The name "Zapopan" derives from a Nahuatl pictogram represented by 
            the fruit tree of zapotes with a flag at its side and comes from the 
            Nahuatl word tzapopantl, which means "place populated with zapotes".
          </p>

          <a
            href="https://www.zapopan.gob.mx/v3/ciudad"
            style={{ color: "black" }}
          >
            <p>More information about Zapopan</p>
          </a>
        </div>
        {/* Nuestras Sedes */}
        <div style={{ backgroundColor: "#313134" }}>
          <SedesSection />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Ciudad;

class SedesSection extends React.Component {
  render() {
    return (
      <div>
        <h3
          style={{
            color: "white",
            fontWeight: "500",
            padding: "30px 0px",
          }}
        >
          Our Locations
        </h3>
        {sedesData.Sedes.map((sede, key) => (
          <div>
            <h5 style={{ color: "white" }}>{sede.title}</h5>
            <div>
              <img
                src={require(`../../img/sedes/${sede.image}`)}
                alt={"img_" + sede.image}
                style={{
                  maxWidth: "50%",
                  objectFit: "cover",
                }}
              />
              <p style={{ color: "white", fontSize:'18px', fontWeight:'lighter' }}>{sede.address}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const sedesData = {
  Sedes: [
    {
      title: "Centro Cultural Constitución",
      address:
        "Avenida Manuel M. Dieguez 369 esq, Constitución, 45180 Zapopan, Jal.",
      image: "ccconstitucionImg.jpg",
    },
    {
      title: "Centro Cultural Constitución",
      address:
        "Avenida Manuel M. Dieguez 369 esq, Constitución, 45180 Zapopan, Jal.",
      image: "ccconstitucionImg-2.jpg",
    },
    {
      title: "Palacio De La Cultura Y Los Congresos",
      address:
        "Av. Central Guillermo Gonzalez Camarena 375, Poniente, 45136 Zapopan, Jal.",
      image: "palccoImg.jpg",
    },
    {
      title: "Palacio De La Cultura Y Los Congresos",
      address:
        "Av. Central Guillermo Gonzalez Camarena 375, Poniente, 45136 Zapopan, Jal.",
      image: "palccoImg-2.jpg",
    },
    {
      title: "Catedral de Guadalajara",
      address:
        "Av. Fray Antonio Alcalde 10, Zona Centro, 44100 Guadalajara, Jal.",
      image: "catedralmg.jpg",
    },
    {
      title: "Catedral de Guadalajara",
      address:
        "Av. Fray Antonio Alcalde 10, Zona Centro, 44100 Guadalajara, Jal.",
      image: "catedralmg.webp",
    },
  ],
};
