import React, { Component } from 'react';
import SedesList from '../data/sedes.json';
import { Container, ListGroup } from 'react-bootstrap';
import Header from './HeaderEn';
import Footer from './FooterEn';
import './Sedes.css'

class Sedes extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className='SmallHero' />
                <div className='SedesListCont'>
                    <Container>
                        <h1>Event Locations</h1>
                        <ListGroup>
                            {SedesList.Sedes.map((sede, key) => (
                                <ListGroup.Item>
                                    <div className='row'>
                                        <div className='col-sm-2 float-left'>
                                            <img className='img-fluid' src={require('../img/' + sede.img)} alt={sede.name} />
                                        </div>
                                        <div className='col-sm-10 float-right'>
                                            <p><b>{sede.name}</b></p>
                                            <p>Address: {sede.address}</p>
                                            <p>Phone Number: {sede.phone}</p>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        {/*
                            <iframe className='gmap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.6685836082793!2d-100.97377788465602!3d22.138608354338366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842aa20fa88eeaf3%3A0x9340d8cfa3ae6913!2sCalz%20de%20Guadalupe%20705%2C%20San%20Juan%20de%20Guadalupe%2C%20Santuario%2C%20San%20Luis%2C%20S.L.P.!5e0!3m2!1sen!2smx!4v1602873159961!5m2!1sen!2smx" width="600" height="450" frameborder="0" title='mapa' allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        */}
                    </Container>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Sedes;