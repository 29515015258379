import React from 'react';
import Header from './HeaderEn';
import Footer from './FooterEn';
import datalist from '../data/jurado.json';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './JrdDetailed.css';

function JrdDetailed(props) {
    const { match } = props;

    return (
        <div>
            <Header />
            <Container fluid>
                <Row>
                    <Col>
                        <Image fluid src={require('../img/' + datalist.Jurado[match.params.guestID].img)} alt='Jurado'/>
                        
                    </Col>
                    <Col className='floar-right'>
                        <h1>{datalist.Jurado[match.params.guestID].name}</h1>
                        <h2>{datalist.Jurado[match.params.guestID].jobEn}</h2>
                        <p className='bio'>{datalist.Jurado[match.params.guestID].resumeEn}</p>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default JrdDetailed;