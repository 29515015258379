import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <p>© 2023 Todos los derechos reservados. FESTIVAL DE ÓPERA ZAPOPAN | 8VO CONCURSO INTERNACIONAL DE CANTO LINUS LERNER</p>
            </div>
        )
    }
}

export default Footer