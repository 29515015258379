import React from "react";
import { Button } from "react-bootstrap";

const Hero = (props) => {
    const style = {
      top: 0,
      left: 0,
      height: "600px",
      background: `linear-gradient(45deg, #000000 0%, rgba(0, 0, 0, 0) 85.06%), url(${props.image})`,
      display: "flex",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "align-items": "center",
    };
    return (
      <div style={style}>
        <div>
          <div
            className="text-white"
            style={{
              alignSelf: "center",
              padding: "0px 25px",
            }}
          >
            <h2 className="text-xl-left">{props.title}</h2>
            <p className="text-sm-left">{props.subtitle}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "15px",
              }}
            >

              <Button
                variant="primary"
                style={{
                  color: "white",
                  backgroundColor: "orange",
                  borderColor: "orange",
                  borderRadius: "25px",
                }}
                hidden = {props.buttonsHiden}
              >
                Inscribete
              </Button>
              <Button
                variant="outline-primary"
                style={{
                  color: "white",
                  borderColor: "orange",
                  borderRadius: "25px",
                }}
                hidden = {props.buttonsHiden}
              >
                Contáctanos
              </Button>
              
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Hero