import React, { Component } from 'react';
import SedesList from '../data/sedes.json';
import { Container, ListGroup } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import './Sedes.css'

class Sedes extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className='SmallHero' />
                <div className='SedesListCont'>
                    <Container>
                        <h1>Sedes</h1>
                        <ListGroup>
                            {SedesList.Sedes.map((sede, key) => (
                                <ListGroup.Item>
                                    <div className='row'>
                                        <div className='col-sm-2 float-left'>
                                            <img className='img-fluid' src={require('../img/' + sede.img)} alt={sede.name} />
                                        </div>
                                        <div className='col-sm-10 float-right'>
                                            <p><b>{sede.name}</b></p>
                                            <p>Direccion: {sede.address}</p>
                                            <p>Telefono: {sede.phone}</p>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Container>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Sedes;