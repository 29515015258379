import React from "react";
import dirArt from "../../data/director_artistico.json";
import dirGral from "../../data/directora_gral.json";
import prod from "../../data/produccion.json";
import docentes from "../../data/maestros.json";
import pianistas from "../../data/pianistas.json";
import directores from '../../data/directores.json';
import asistentes from '../../data/tas.json';
import Header from "../Header";
import Hero from "../HeroComponent";
import heroImage from "../../img/hero/hero_zapopan.jpg";
import Footer from "../Footer";
import { Card, CardGroup, Container, Row, Col } from "react-bootstrap";

const Equipo = () => {
  return (
    <div>
      <Header />
      <Hero
        image={heroImage}
        title={"Nuestro Equipo"}
        subtitle={"Conoce mas de nuestro fabuloso equipo"}
        buttonsHiden={true}
      />
      <Container style={{ padding: "50px 0px" }}>
        <h3 style={{padding: "25px 0px",}}>Director Artístico</h3>
        <CardGroup>
          {dirArt.Maestros.map((dirA, key) => (
            <MaestrosCard user={dirA} />
          ))}
        </CardGroup>
        <h3 style={{padding: "25px 0px",}}>Directora General</h3>
        <CardGroup>
          {dirGral.Maestros.map((dirG, key) => (
            <MaestrosCard user={dirG} />
          ))}
        </CardGroup>
        <h3 style={{padding: "25px 0px",}}>Producción</h3>
        <CardGroup>
          {prod.Maestros.map((produccion, key) => (
            <MaestrosCard user={produccion} />
          ))}
        </CardGroup>
        <h3 style={{padding: "25px 0px",}}>Maestros de Canto</h3>
        <Row xs={1} md={2} lg={4}>
          {docentes.Maestros.map((docente, key) => (
            <Col>
              <MaestrosCard user={docente} />
            </Col>
          ))}
        </Row>
        <h3 style={{padding: "25px 0px",}}>Pianistas</h3>
        <CardGroup>
          {pianistas.Maestros.map((pianista, key) => (
            <MaestrosCard user={pianista} />
          ))}
        </CardGroup>
        <h3 style={{padding: "25px 0px",}}>Directores</h3>
        <CardGroup>
          {directores.Maestros.map((director, key) => (
            <MaestrosCard user={director} />
          ))}
        </CardGroup>
        <h3 style={{padding: "25px 0px",}}>Maestros Asistentes</h3>
        <CardGroup>
          {asistentes.Maestros.map((asistente, key) => (
            <MaestrosCard user={asistente} />
          ))}
        </CardGroup>
      </Container>
      <Footer />
    </div>
  );
};

export default Equipo;

const MaestrosCard = (props) => {
  return (
    <Card border="dark" className='p-3' style={{ width: '18rem', textAlign: "center" }}>
      <Card.Img
        variant={"top"}
        alt={props.user.name}
        src={require(`../../img/${props.user.img}`)}
        style={{
          maxWidth: "250px",
          minHeight:"300px",
          maxHeight: "300px",
          objectFit: "cover",
          alignSelf: "center"
        }}
      />
      <Card.Title>{props.user.name}</Card.Title>
      <Card.Subtitle>{props.user.job}</Card.Subtitle>
      <Card.Text style={{ textAlign: "justify" }}>{props.user.summary}</Card.Text>
    </Card>
  );
};
