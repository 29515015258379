import React from 'react'
import Footer from './Footer'
import Header from './Header'
import './Proximamente.css'

class Proximamente extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="SmallHero"></div>
                <div className="ProximamenteContainer">
                    <h1 className="display-3">Próximamente</h1>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Proximamente