import React from 'react';
import docentes from "../data/maestros.json";
//import conferencistas from '../data/conferencistas.json';
import pianistas from '../data/pianistas.json';
import { Link } from 'react-router-dom';
import './MaestrosList.css'
import Header from './HeaderEn';
import Footer from './FooterEn';
import { Container, ListGroup } from 'react-bootstrap';

const MaestrosListEn = () => {
    return (
        <div>
            <Header/>
            <div className='SmallHero'/>
            <Container className='cont'>
                <h1>Lecturers</h1>
                <ListGroup>
                    {docentes.Maestros.map((docente, key) => (
                        <ListGroup.Item><Link to={'/tch-details/' + docente.id}>{docente.listName}</Link></ListGroup.Item>
                    ))}
                </ListGroup>
                {/*<Container className='cont'>
                    <p>* The teachers marked will only be able to attend the event depending on their respective countries COVID-19 guidelines</p>
                    </Container>*/}
                {/*<h1>Speakers</h1>
                <ListGroup>
                    {conferencistas.Maestros.map((conferencista, key) => (
                        <ListGroup.Item><Link to={'/spk-details/' + conferencista.id}>{conferencista.listName}</Link></ListGroup.Item>
                    ))}
                </ListGroup>*/}
                <h1>Pianists</h1>
                <ListGroup>
                    {pianistas.Maestros.map((pianista, key) => (
                        <ListGroup.Item><Link to={'/pia-details/' + pianista.id}>{pianista.listName}</Link></ListGroup.Item>
                    ))}
                </ListGroup>
            </Container>
            <Footer/>
        </div>
    );
}

export default MaestrosListEn;