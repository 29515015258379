import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { Image } from "react-bootstrap";
import programaImg from '../../data/Programacion-FOZ-update.png';
import Hero from "../HeroComponent";
import heroImage from "../../img/hero/hero_zapopan.jpg";

class Programa extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <Hero
                    image={heroImage}
                    title={"Nuestro Equipo"}
                    subtitle={"Conoce mas de nuestro fabuloso equipo"}
                    buttonsHiden={true}
                />
                <Image src={programaImg} fluid/>
                <Footer/>
            </div>
        )
    }
}
export default Programa