import React from 'react';
import datalist from "../data/jurado.json";
import { Link } from 'react-router-dom';
import './JuradoList.css';
import { Container, ListGroup } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

const JuradoList = () => {
    return (
        <div>
            <Header />
            <div className='SmallHero' />
            <Container className='cont'>
                <h1>Jurado</h1>
                <ListGroup>
                    {datalist.Jurado.map((jurado, key) => (
                        <ListGroup.Item><Link to={'/jrd-details/' + jurado.id}>{jurado.name}</Link></ListGroup.Item>
                    ))}
                </ListGroup>
            </Container>
            <Footer />
        </div>
    )
}

export default JuradoList;