import React from 'react'
import { Link } from 'react-router-dom'
import './Header.css'
import fesPDF from '../data/ConvocatoriaFestivalDeOperaZapopan2023.pdf';
import compPDF from '../data/ConvocatoriaConcursoOpera2023.pdf'
import { Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';

class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasScrolled: false,
        }
    }

    render() {
        return (

            <Navbar collapseOnSelect bg='dark' expand='lg' variant='dark' sticky='top'>
                <Navbar.Brand>
                    <Link to='/'><img src={require('../img/logo/logo-foz.png')} id='logoImg' alt="logo"></img></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='mr-auto'>
                        {/*Festival*/}
                        <NavDropdown title='Festival' id='collasible-nav-dropdown' >
                            <NavDropdown.Item href={fesPDF} target='blank' rel='noopener noreferrer'>Convocatoria</NavDropdown.Item>
                            <NavDropdown.Item href='https://docs.google.com/forms/d/e/1FAIpQLSf_UGugAr21mkkpJLUyHIn7AOb4_th1wBrhe6HbnvTMnf_mYg/viewform?usp=sf_link' target='blank' rel='noopener noreferrer'>Formulario</NavDropdown.Item>
                        </NavDropdown>
                        {/*Concurso*/}
                        <NavDropdown title='Concurso' id='collasible-nav-dropdown' >
                            <NavDropdown.Item href={compPDF} target='blank' rel='noopener noreferrer'>Convocatoria</NavDropdown.Item>
                            <NavDropdown.Item href='https://docs.google.com/forms/d/e/1FAIpQLSdWmMc76Yr3oDWko4sIXzjI5G8jIwR_DnDRks6z_DQuoPXcPg/viewform?usp=sf_link' target='blank' rel='noopener noreferrer'>Formulario</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link><Link className='link' to='/ciudad'>Ciudad</Link></Nav.Link>
                        <Nav.Link><Link className='link' to='/equipo'>Equipo</Link></Nav.Link>
                        <Nav.Link><Link className='link' to='/programa'>Programacion</Link></Nav.Link>
                        <Nav.Link><Link className='link' to='/noticias'>Noticias</Link></Nav.Link>
                        <Nav.Link><Link className='link' to='/patrocinadores'>Patrocinadores</Link></Nav.Link>
                        <Nav.Link><Link className='link' to='/contacto'>Contacto</Link></Nav.Link>
                    </Nav>
                    <Button inline variant='primary'><Link className='link' to='/en'>ENG</Link></Button>
                </Navbar.Collapse>

            </Navbar>


        )
    }
}

export default Header
