import React from 'react';
import datalist from "../data/conferencistas.json";
import Header from './HeaderEn';
import Footer from './FooterEn';
import { Col, Container, Row, Image } from 'react-bootstrap';
import './MstDetailed.css'

function CnfDetailedEn(props) {
    const { match } = props;

    return (
        <div>
            <Header />
            <Container fluid>
                <Row>
                    <Col className='float-left'>
                        <Image fluid src={require('../img/' + datalist.Maestros[match.params.guestID].img)} alt='Maestros' />
                    </Col>
                    <Col className='float-right'>
                        <h1>{datalist.Maestros[match.params.guestID].name}</h1>
                        <h2>{datalist.Maestros[match.params.guestID].jobEn}</h2>
                        <p className='bio'>{datalist.Maestros[match.params.guestID].resumeEn}</p>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default CnfDetailedEn;