import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './InvCarousel.css';

class InvCarousel extends React.Component {
    render() {
        return (
            <Container>
                <h3>Invitan</h3>
                <Carousel class='carousel-item'>
                    <Carousel.Item interval='500'>
                        <img
                            className='h-100 img-fluid'
                            src={require('../img/sponsors/logo-palcco.jpeg')}
                            alt='Palacio de la Cultura y los Congresos' />
                    </Carousel.Item>
                </Carousel>
            </Container>
        )
    };
}
export default InvCarousel;