import React from "react";
import "../containers/App.css";
//import FavSection from "./FavSection";
import Footer from "./Footer";
import Header from "./Header";
import InvCarousel from "./InvCarousel";
import heroImage from "../img/hero/hero_zapopan.jpg";
import Hero from "./HeroComponent";
import { Col, Container, Row } from "react-bootstrap";

const Home = () => {
  return (
    <div className="App">
      <Header />
      <div>
        <Hero
          image={heroImage}
          title={"Bienvenido al Festival de Opera"}
          subtitle={"En la ciudad de Zapopan"}
          buttonsHiden={true}
        />
        <div style={{ textAlign: "center" }}>
          <h3 style={{ padding: "35px 100px 0px" }}>Sobre el Festival</h3>
          <div style={{ textAlign: "justify" }}>
            <Container>
              <Row>
                <Col>
                  <p>
                    El Festival y el Concurso Internacional de Canto Linus
                    Lerner son realizados con el objetivo de estimular el
                    desarrollo artístico y personal de jóvenes cantantes de todo
                    el mundo y promover el conocimiento e interés por el Canto
                    Lírico en nuestro país con la colaboración de docentes
                    internacionales de gran renombre y alto nivel. Así mismo
                    buscamos acercar la ópera a al público mexicano con
                    espectáculos operísticos de gran calidad en diversos
                    formatos como: recitales, conciertos de gala, eliminatorias
                    del Concurso de Canto y producciones de óperas completas con
                    orquesta en vivo.
                  </p>
                  <p>
                    El Festival comienza con 3 ediciones del Festival de Ópera
                    de Oaxaca, siguiendo de 5 ediciones del Festival de Ópera
                    San Luis y 1 edición del Festival de Ópera Sin Límites.
                  </p>

                  <p>
                    Se han presentado 13 óperas completas con orquesta y
                    producciones profesionales de alto nivel, contando con 25
                    docentes nacionales e internacionales, más de 1200 alumnos
                    activos de 23 países diferentes y más de 10,000 asistentes
                    de público.
                  </p>
                </Col>
                <Col>
                  <img
                    src={require("../img/homeImages/festival_1.jpg")}
                    alt="festival1"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div>
          <h3>Sobre el Concurso</h3>
          <div style={{ textAlign: "justify", padding: "35px 100px" }}>
            <Container>
              <Row>
                <Col>
                  <img
                    src={require("../img/homeImages/festival_4.jpg")}
                    alt="festival4"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Col>
                <Col>
                  <p>
                    Gracias al Concurso, que se realiza dentro del Festival, los
                    ganadores reciben estímulos en efectivo, cursos de canto en
                    Estados Unidos, participaciones en óperas fuera del país así
                    como un renombre internacional al haber ganado un premio de
                    un Concurso Internacional. Se han entregado más de 88
                    premios en efectivo (más de $88,000.00 dólares) y 2 becas en
                    el extranjero, además de papeles en presentaciones,
                    conciertos y talleres nacionales e internacionales con todos
                    los gastos pagados.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div>{/* <FavSection/> */}</div>
        {/*
        <div style={{backgroundColor: '#313134'}}>
          <MaestrosSection/>
        </div> */}
      </div>
      <InvCarousel />
      <Footer />
    </div>
  );
};

export default Home;
