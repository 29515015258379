import React from "react";
import sponsors from '../../data/patrocinadores.json';
import Header from "../Header";
import Hero from "../HeroComponent";
import heroImage from "../../img/hero/hero_zapopan.jpg";
import Footer from "../Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

const Patrocinadores = () => {
  return (
    <div>
      <Header />
      <Hero
        image={heroImage}
        title={"Our Sponsors"}
        subtitle={"The people who make this event possible"}
        buttonsHiden={true}
      />
      <Container style={{ padding: "50px 0px" }}>
        <h3 style={{padding: "25px 0px",}}>Sponsors</h3>
        <Row xs={1} md={1} lg={1}>
          {sponsors.Patrocinadores.map((spo, key) => (
            <Col>
              <SponsorsCard user={spo} />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Patrocinadores;

const SponsorsCard = (props) => {
  return (
    <Card border="dark" className='p-3' style={{ textAlign: "center" }}>
      <Card.Img
        variant={"top"}
        alt={props.user.nombreEn}
        src={require(`../../img/${props.user.img}`)}
        style={{
          minHeight:"300px",
          maxHeight: "300px",
          objectFit: "contain",
          alignSelf: "center"
        }}
      />
      <Card.Title>{props.user.nombreEn}</Card.Title>
    </Card>
  );
};
