import React from 'react';
import Header from './Header';
import './Gallery.css';

const Workshop = () => {
    return (
        <div>
            <Header/>
            <div className='container my-3 p-3 border'>
                <h1>2020</h1>
                <div className='card-deck'>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-andrew-stuckey.jpg')}  alt='Clase Magistral Andrew Stuckey' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Andrew Stuckey</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-carsten-wittmoser.jpg')}  alt='Clase Magistral Carsten Wittmoser' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Carsten Wittmoser</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-charles-oppenheim.jpg')}  alt='Clase Magistral Charles Oppenheim' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Charles Oppenheim</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-conchi-moyano.jpg')}  alt='Clase Magistral Conchi Moyano' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Conchi Moyano</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-irena-sylia.jpg')}  alt='Clase Magistral Irena Sylia' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Irena Sylia</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-james-demster.jpg')}  alt='Clase Magistral James Demster' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-liliana-del-conde.jpg')}  alt='Clase Magistral Liliana del Conde' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Liliana del Conde</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-linus-lerner.jpeg')}  alt='Clase Magistral Linus Lerner' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Linus Lerner</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-marcelo-buscaino.jpg')}  alt='Clase Magistral Marcelo Buscaino' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-maria-russo.jpg')}  alt='Clase Magistral Maria Russo' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral James Demster</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-sandra-lopez.jpg')}  alt='Clase Magistral Sandra Lopez' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral James Demster</h5>
                        </div>
                    </div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-sergio-vazques.jpg')}  alt='Clase Magistral Sergio Vazques' />
                        <div className='card-body'>
                            <h5>5FOSL Clase Magistral Sergio Vazques</h5>
                        </div>
                    </div>
                    <div class="w-100 d-none d-sm-block d-md-none">{/*Wrap every 2 on small*/}</div>
                    <div class="w-100 d-none d-md-block d-lg-none">{/*Wrap every 3 on medium*/}</div>
                    <div class="w-100 d-none d-lg-block">{/*Wrap every 4 on large*/}</div>
                    <div className='card bg-light text-dark mb-4'>
                        <img className='card-img-top img-fluid' src={require('../img/5fosl-opera-casos.jpeg')}  alt='Opera, Casos de la Vida Real' />
                        <div className='card-body'>
                            <h5>5FOSL Opera, Casos de la Vida Real</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Workshop;