import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Abril2023 from '../../data/News/RevistaDigital-Abril2023.pdf';
import Hero from "../HeroComponent";
import heroImage from "../../img/hero/hero_zapopan.jpg";
import { Col, Container, Row } from 'react-bootstrap';

class Noticias extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <Hero
                    image={heroImage}
                    title={"News"}
                    subtitle={"Get to know our memorable moments"}
                    buttonsHiden={true}
                />
                <Container style={{minHeight: "500px"}}>
                    <Row>
                        <Col>
                            <h1>News Articles</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <a href={Abril2023} target='blank' rel='noopener noreferrer'>April 2023 - New Hamburg to hold festival FeMusik</a>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        )
    }
}
export default Noticias